<template>
  <div>
    <div class="d-flex justify-space-between mb-8 align-center">
      <h2 class="main-title mb-0">Sites</h2>
      <v-btn color="primary" class="px-4 white--text" @click="addSite">
        <v-icon class="mr-2 white--text">mdi-plus</v-icon>New site
      </v-btn>
    </div>
    <SitesAdminTable
      :headers="headers"
      :data="sites"
      :loading="loading"
      :selectItem="selectItem"
      :unselectItem="unselectItem"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SitesAdminTable from "@/components/SitesAdminTable";
import { allSubscriptions } from "@/helpers/variables";

export default {
  name: "SitesAdmin",
  components: {
    SitesAdminTable,
  },
  data() {
    return {
      selected: [],
      tableData: [],
      issueStatus: [],
      itemsPerPage: 10,
      title: "",
      searchText: "",
      headers: [
        { text: "Org", sortable: true, value: "org_name" },
        { text: "Site", sortable: true, value: "name" },
        { text: "Number of turbines", sortable: true, value: "turbines" },
        { text: "State", sortable: true, value: "state" },
        {
          text: "Subscription",
          sortable: true,
          value: "current_subscription_name",
        },
        {
          text: "Subscription start date",
          sortable: false,
          value: "current_subscription.start_date",
        },
        {
          text: "Subscription end date",
          sortable: false,
          value: "current_subscription.end_date",
        },
      ],
      allSubscriptions,
    };
  },
  computed: {
    ...mapState({
      allSites: (state) => state.sites.allSites,
      loading: (state) => state.sites.loading.getAllSites,
    }),
    sites() {
      let sites = [];
      sites = this.allSites.map((site) => ({
        ...site,
        org_name: site.org.name,
        current_subscription_name:
          site.subscriptions.length > 0
            ? this.allSubscriptions.filter(
                (sub) =>
                  sub.sow_def_id === site.current_subscription?.sow_def_id,
              )[0]?.name
            : "",
      }));

      return sites;
    },
  },
  methods: {
    ...mapActions({
      getAllSites: "sites/getAllSites",
    }),
    selectItem(item) {
      item.selected = true;
    },
    unselectItem(item) {
      item.selected = false;
    },
    addSite() {
      this.$router.push({
        path: "/admin/sites/new",
      });
    },
  },
  beforeMount() {
    this.getAllSites();
  },
};
</script>

<style lang="scss" scoped>
.main-title {
  font-size: 1.75rem;
}
</style>
