<template>
  <div>
    <div class="d-flex flex-wrap align-center justify-space-between">
      <div class="d-flex flex-wrap align-center mr-3">
        <div class="mr-3 mb-3 search-filter-wrapper">
          <v-text-field
            clearable
            solo
            dense
            label="Search..."
            append-icon="mdi-magnify"
            hide-details
            class="no-border"
            data-ga="sites_admin_table_search"
            @input="updateSearch"
          ></v-text-field>
        </div>
      </div>

      <div class="d-flex flex-wrap align-center">
        <div class="mr-3 mb-3 column-filter-wrapper">
          <v-tooltip top :open-delay="popupDelay">
            <template v-slot:activator="{ on, attrs }">
              <v-icon id="cog-icon" class="pointer" v-on="on" v-bind="attrs"
                >mdi-cog-outline</v-icon
              >
            </template>
            <span>Column filters</span>
          </v-tooltip>
          <CustomMenuFilter
            :menuOpen="colFilterMenuOpen"
            menuActivator="#cog-icon"
            :menuItems="columnFilterMenuItems"
            :showAll="showAllCols"
            offset="y"
            filterType="column"
            table="sites_admin_table"
            @changeFilters="toggleColSelection"
            @clearSelectedItems="clearSelectedColItems"
            @setSearchText="setColumnFilterSearchText"
            @clearFilterSearch="clearColumnFilterSearch"
          />
        </div>
      </div>
    </div>
    <div>
      <DataTable
        className="no-background clickable-table sites-table"
        itemKey="site_id"
        sortBy="name"
        :search="searchText"
        :showSelect="false"
        :headers="mutableTableHeaders"
        :items="data"
        :itemsPerPage="itemsPerPage"
        :loading="loading"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              @mouseover="selectItem(item)"
              @mouseleave="unselectItem(item)"
            >
              <td v-if="checkColumn('org_name')">
                <span class="org_name">{{ item.org_name }}</span>
              </td>
              <td v-if="checkColumn('name')">
                <span class="site-name">
                  <router-link
                    class="site-link"
                    :to="{
                      name: 'EditSite',
                      params: {
                        siteId: item.id,
                      },
                    }"
                  >
                    {{ item.name }}</router-link
                  >
                </span>
              </td>
              <td v-if="checkColumn('turbines')">
                {{ item.turbines.length }}
              </td>
              <td v-if="checkColumn('state')">
                <span class="state" v-if="item.state">{{
                  formatState(item.state)
                }}</span>
              </td>
              <td v-if="checkColumn('current_subscription_name')">
                <span class="subscription">{{
                  item.current_subscription
                    ? item.current_subscription_name
                    : null
                }}</span>
              </td>
              <td v-if="checkColumn('current_subscription.start_date')">
                <span class="subscription_start_date">{{
                  item.current_subscription
                    ? item.current_subscription.start_date
                    : null
                }}</span>
              </td>
              <td v-if="checkColumn('current_subscription.end_date')">
                <span class="subscription_end_date">{{
                  item.current_subscription
                    ? item.current_subscription.end_date
                    : null
                }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable";
import CustomMenuFilter from "./CustomMenuFilter";
import { popupDelay } from "@/helpers/variables";

export default {
  name: "SitesAdminTable",
  components: {
    DataTable,
    CustomMenuFilter,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    selectItem: {
      type: Function,
      default: () => {},
    },
    unselectItem: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      columns: [],
      displayColumns: [],
      itemsPerPage: 10,
      searchText: "",
      colFilterMenuOpen: false,
      searchColumnMenuText: "",
      showAllCols: false,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      popupDelay,
    };
  },
  watch: {
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
  },
  computed: {
    tableHeaders() {
      let headerArr = [];
      if (this.headers.length > 0) {
        let count = 1;
        for (const header of this.headers) {
          let headerObj = {};
          headerObj.id = count;
          headerObj.text = header.text;
          headerObj.value = header.value;
          headerObj.sortable = header.sortable;
          headerObj.show = true;
          headerArr.push(headerObj);
          count++;
        }
      }

      return headerArr;
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
  },
  methods: {
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    updateSearch(e) {
      this.searchText = e;
    },
    // Splits the state into an array of words, capitalizes the first letter
    // of the first word, and then joins the words back together with a comma
    // and space between them
    formatState(state) {
      let s = `${state[0].toUpperCase()}${state.slice(1)}`;
      let ss = s.split("-");
      if (ss.length > 1) {
        return ss[0] + ", " + ss.slice(1).join(" ");
      } else {
        return s;
      }
    },
  },
};
</script>
<style lang="scss">
.sites-table th {
  white-space: normal;
}
</style>
<style lang="scss" scoped>
.site-link {
  color: var(--v-black1-base) !important;
  text-decoration: none;
}
.site-link:hover {
  color: var(--v-primary-base) !important;
  text-decoration: underline;
}
</style>
