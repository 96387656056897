var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('div',{staticClass:"d-flex flex-wrap align-center mr-3"},[_c('div',{staticClass:"mr-3 mb-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"clearable":"","solo":"","dense":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"sites_admin_table_search"},on:{"input":_vm.updateSearch}})],1)]),_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('div',{staticClass:"mr-3 mb-3 column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"sites_admin_table"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)])]),_c('div',[_c('DataTable',{attrs:{"className":"no-background clickable-table sites-table","itemKey":"site_id","sortBy":"name","search":_vm.searchText,"showSelect":false,"headers":_vm.mutableTableHeaders,"items":_vm.data,"itemsPerPage":_vm.itemsPerPage,"loading":_vm.loading},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unselectItem(item)}}},[(_vm.checkColumn('org_name'))?_c('td',[_c('span',{staticClass:"org_name"},[_vm._v(_vm._s(item.org_name))])]):_vm._e(),(_vm.checkColumn('name'))?_c('td',[_c('span',{staticClass:"site-name"},[_c('router-link',{staticClass:"site-link",attrs:{"to":{
                    name: 'EditSite',
                    params: {
                      siteId: item.id,
                    },
                  }}},[_vm._v(" "+_vm._s(item.name))])],1)]):_vm._e(),(_vm.checkColumn('turbines'))?_c('td',[_vm._v(" "+_vm._s(item.turbines.length)+" ")]):_vm._e(),(_vm.checkColumn('state'))?_c('td',[(item.state)?_c('span',{staticClass:"state"},[_vm._v(_vm._s(_vm.formatState(item.state)))]):_vm._e()]):_vm._e(),(_vm.checkColumn('current_subscription_name'))?_c('td',[_c('span',{staticClass:"subscription"},[_vm._v(_vm._s(item.current_subscription ? item.current_subscription_name : null))])]):_vm._e(),(_vm.checkColumn('current_subscription.start_date'))?_c('td',[_c('span',{staticClass:"subscription_start_date"},[_vm._v(_vm._s(item.current_subscription ? item.current_subscription.start_date : null))])]):_vm._e(),(_vm.checkColumn('current_subscription.end_date'))?_c('td',[_c('span',{staticClass:"subscription_end_date"},[_vm._v(_vm._s(item.current_subscription ? item.current_subscription.end_date : null))])]):_vm._e()])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }